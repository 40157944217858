import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useRef, useState } from 'react';

export interface IHeaderState {
  isExpanded: boolean;
  hasScrolled: boolean;
  activeLinkKey: string;
}

interface INavLinks {
  key?: string;
  label: string;
  to: string;
  extern?: boolean;
}

export interface IHeaderProps {
  logoImg: any;
}

export const Header = (props: { resizeLogo?: boolean }) => {
  const { logoImg } = useStaticQuery(
    graphql`
      query {
        logoImg: file(relativePath: { eq: "logo/Logo_Becher.png" }) {
          childImageSharp {
            fluid(maxWidth: 200, maxHeight: 200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const ref = useRef();
  let sentinalObserver: IntersectionObserver;
  // let sectionObservers: IntersectionObserver[] = [];
  const navLinks: INavLinks[] = [
    {
      label: 'Bar',
      to: '/',
    },
    {
      label: 'Wodka Essentials',
      to: '/morethanrussianstandard',
    },
    {
      label: 'How to play',
      to: '/howtoplay',
    },
    // {
    //   label: 'Merch',
    //   to: 'https://shop.bar-sigma.de/',
    //   extern: true,
    // },
    {
      label: 'About Sigma',
      to: '/aboutus',
    },
    // {
    //   label: 'sustainability',
    //   to: '/whatwedoforoutfuture',
    // },
  ];

  // const [activeLink, setActiveLink] = useState('');
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  // const [documentHeight, setDocumentHeight] = useState(-1);

  // const handleResize = () => {
  //   const documentEl = document.documentElement;
  //   setDocumentHeight(documentEl.clientHeight);
  // };
  // const handleScroll = () => {
  //   setIsExpanded(false);
  // };

  // const scrollToTop = () => {
  //   const documentEl = document.documentElement;
  //   navigate('/').then(() => {
  //     documentEl.scrollTo({
  //       top: 0,
  //       behavior: 'smooth',
  //     });
  //   });
  // };

  useEffect(() => {
    const documentEl = document.documentElement;
    const sentinal = document.getElementById('sentinal');
    if (sentinal) {
      sentinalObserver = new IntersectionObserver(
        ([entry]) => {
          setHasScrolled(!entry.isIntersecting);
        },
        {
          threshold: 1,
        }
      );
      sentinalObserver.observe(sentinal);
    }
    // window.addEventListener('resize', () => setIsExpanded(false));
  }, []);

  const createLink = (link: INavLinks, className?: string) => {
    if (link.extern) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={`${
            /* activeLink === link.key ? 'text-red font-normal' : */ ''
          } hover:text-red border-t-2 md:border-t-0 border-opacity-20 border-gray-50 hover:font-normal block mt-4 py-1 text-left text-white no-underline md:inline-block md:mt-0 ${className}`}
          href={link.to}
        >
          {link.label.toUpperCase() + '.'}
        </a>
      );
    }
    return (
      <Link
        className={`${
          /* activeLink === link.key ? 'text-red font-normal' : */ ''
        } hover:text-red border-t-2 md:border-t-0 border-opacity-20 border-gray-50 hover:font-normal block mt-4 py-1 text-left text-white no-underline md:inline-block md:mt-0 ${className}`}
        key={link.key}
        to={link.to}
        onClick={() => setIsExpanded(false)}
        activeClassName="text-red"
      >
        {link.label.toUpperCase() + '.'}
      </Link>
    );
  };
  return (
    <header
      className="sigma-header px-1 md:px-4 xl:px-0 grid grid-cols-1 md:grid-cols-12 sticky top-0 z-50"
      style={{ opacity: 0.9 }}
    >
      <div className="col-span-12 flex flex-wrap items-center justify-start md:justify-between py-2 md:px-0 md:py-2">
        <button
          className="items-center block px-3 py-2 text-white md:hidden absolute z-10"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <svg
            className="w-5 h-5 fill-current "
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
        <div className="md:hidden flex-grow h-14">
          <Link to="/" key="home">
            <Img
              className={`z-50 absolute left-0 right-0 mx-auto h-20 w-20 cursor-pointer`}
              fluid={logoImg.childImageSharp.fluid}
              alt="Sigma Logo"
            />
          </Link>
        </div>
        <nav
          className={`sigma-nav absolute top-14 left-0 md:top-0  md:pt-0 md:pb-0 md:mt-0 w-full text-lg font-medium`}
        >
          {/* <div className="hidden md:flex top-0 left-0 bottom-0 absolute items-center px-3">
            {createLink(navLinks[0])}
          </div> */}

          <div
            className={`${
              isExpanded ? 'flex' : 'hidden'
            } inner-nav py-6 md:py-0 mx-auto md:flex md:flex-row flex-col items-center justify-between md:items-center`}
          >
            {/* {createLink(navLinks[0], 'block md:hidden ')} */}
            {navLinks.slice(0, 2).map((link, i) => createLink(link))}

            <div className="sigma-logo-wrapper  hidden md:block">
              <Link to="/" key="home" className="inline-block px-1">
                <Img
                  className={`${
                    hasScrolled || !props.resizeLogo ? 'small' : ''
                  } sigma-logo cursor-pointer`}
                  fluid={logoImg.childImageSharp.fluid}
                  alt="Sigma Logo"
                />{' '}
              </Link>
            </div>

            {navLinks
              .slice(2, 4)
              .map((link, i) => createLink(link, 'text-right'))}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
