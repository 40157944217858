import "@fontsource/noto-sans/200.css";
import "@fontsource/noto-sans/300.css";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/700.css";
import "@fontsource/noto-sans/800.css";


import "@fontsource/bitter/200.css";
import "@fontsource/bitter/300.css";
import "@fontsource/bitter/400.css";
import "@fontsource/bitter/500.css";
import "@fontsource/bitter/600.css";
import "@fontsource/bitter/700.css";
import "@fontsource/bitter/800.css";

import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';
import { graphql, Link, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import FooterImage from '../images/sigma_footer.svg';
import Header from './header';

const Layout = (props: React.PropsWithChildren<{ isHome?: boolean }>) => {

  const { bannerImg, bgImg, logoInvers } = useStaticQuery(
    graphql`
      query {
        bannerImg: file(relativePath: { eq: "ball_in_becher.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bgImg: file(relativePath: { eq: "background_calk.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3840, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        logoInvers: file(relativePath: { eq: "logo/Logo_Clean_invers.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );
  const location = useLocation();

  const backgroundFluidImageStack = [
    bgImg.childImageSharp.fluid,
    `linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4))`,
  ].reverse();
  return (
    <div id="sigma-area" className="flex flex-col min-h-screen font-sans">
      <div id="sentinal" className="absolute"></div>
      <Header resizeLogo={props.isHome} />
      {props.isHome && (
        <div className=" w-full mx-auto fixed left-0 right-0 top-0 max-h-screen">
          <div className="sigma-banner relative">
            <div
              style={{ textShadow: '0px 0px 10px rgb(58 41 41)' }}
              className="uppercase text-center text-red text-5xl font-normal absolute top-1/3 md:top-2/4 z-10 left-0 right-0"
            >
              <p> NUR 4 GIRLS, DIE NE BAR AUFMACHEN. #BARSIGMA</p>
    
            </div>

            <Img
              className="h-full"
              fluid={bannerImg.childImageSharp.fluid}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
              alt="SIGMA Banner"
            />
            <div className="absolute arrow-bounce md:hidden bottom-14 right-0 left-0 border-none rounded-md text-red items-center justify-center flex border-2 z-10 h-16">
              <Link className="no-underline text-red" to="/#barsigma">
                <FontAwesomeIcon
                  className="bounce-3 arrow-bounce"
                  size="3x"
                  icon={faAngleDoubleDown}
                ></FontAwesomeIcon>
              </Link>
            </div>
          </div>
        </div>
      )}
      {props.isHome && <div style={{ height: '600px' }}></div>}
      <BackgroundImage
        Tag="div"
        fluid={backgroundFluidImageStack}
        className="sigma-bg h-100 flex-grow"
      >
        <main
          className={`${
            !props.isHome ? 'pt-16' : ''
          } flex-1 w-full mx-auto text-xl md:text-2xl pb-16`}
        >
          <div>{props.children}</div>
          <div
            style={{
              width: '50vw',
              position: 'fixed',
              right: '-15vw',
              bottom: '-18vw',
              opacity: 0.1,
              zIndex: -50,
            }}
          >
            <Img
              fluid={logoInvers.childImageSharp.fluid}
              alt="SIGMA Tray Logo Invers"
            />
          </div>
        </main>

        <footer className="pt-4 text-md w-full text-white font-light absolute bottom-0">
          <div className="w-full" style={{ marginBottom: '-1px' }}>
            <img
              src={FooterImage}
              className="w-full"
              alt="SIGMA Footer Image"
            ></img>
          </div>
          <div className="w-full bg-red">
            <nav className="p-4 mx-auto md:p-8 text-center">
              <Link
                className="ml-2 block mt-4 text-white no-underline md:inline-block md:mt-0"
                key="impressum"
                to="/legalnotice"
              >
                Impressum
              </Link>
              <span className="hidden md:inline">&nbsp;|&nbsp;</span>
              <Link
                className="block mt-4 text-white no-underline md:inline-block md:mt-0"
                key="datenschutz"
                to="/privacy"
              >
                Datenschutzerklärung
              </Link>
              <span className="hidden md:inline">&nbsp;|&nbsp;</span>
              <div className="mt-4 block md:inline">
                ©2021 SIGMA ASSOCIATION GbR
              </div>
            </nav>
          </div>
        </footer>
      </BackgroundImage>
      <div className="fixed bottom-0 w-full">
        <CookieConsent
          expires={30}
          debug={false}
          // onAccept={() => initializeAndTrack(location)}
          location="bottom"
          buttonText="Ich stimme zu"
          enableDeclineButton
          declineButtonText="Nur notwendige Cookies"
          flipButtons
          cookieName="gatsby-gdpr-google-analytics"
        >
          Diese Website verwendet Cookies – nähere Informationen dazu und zu
          Ihren Rechten als Benutzer:innen finden Sie in unserer{' '}
          <Link className="text-red" to="/privacy">
            Datenschutzerklärung.
          </Link>{' '}
          Klicken Sie auf „Ich stimme zu“, um alle Cookies zu akzeptieren.
        </CookieConsent>
      </div>
    </div>
  );
};

export default Layout;
